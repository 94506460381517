import ListViewWrapper from 'core/components/ListViewWrapper';
import Table from 'components/services/ServiceTable';
import FilterComponent from 'components/services/ServiceFilterComponent';
import Service from 'entities/service';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import pagination from 'core/pagination';
import ServiceFilter from 'entities/serviceFilter';
import { CreateButton, TableActions } from 'core/components/TableActions';
import Customer from 'entities/customer';
import Spinner from 'core/components/Spinner';
import { getGenericData, saveGenericData } from 'core/storage/localStorage';

const filterStorageKey = 'services-list-filters';

class ServicesListPage extends Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.handleBottomScroll = this.handleBottomScroll.bind(this);
        this.state = {
            items: [],
            filter: this.initializeFilter(props.match.params.id),
            loading: true,
            hasLoadedAllPages: false,
            page: 0,
            initialCustomer: null,
            filterLoading: true
        };
    }

    initializeFilter(customerId) {
        const saved = getGenericData(filterStorageKey);
        return customerId ? new ServiceFilter({ customerId }) : new ServiceFilter(saved);
    }

    search() {
        saveGenericData(filterStorageKey, this.state.filter);
        this.loadPage(0);
    }

    handleBottomScroll() {
        if (!this.state.hasLoadedAllPages) {
            this.loadPage(this.state.page + 1);
        }
    }

    loadPage(page) {
        if (!this.state.loading) {
            const state = { loading: true, page };
            if (page === 0) { state.items = []; }
            this.setState(state);
        }
        Service.search(this.state.filter, page).then(items =>
            this.setState({
                items: page === 0 ? items : this.state.items.concat(items),
                loading: false,
                hasLoadedAllPages: items.length < pagination.pageSize
            }));
    }

    async componentDidMount() {
        const { customerId } = this.state.filter;
        if (customerId) {
            this.setState({ initialCustomer: await Customer.getSummaryById(customerId), filterLoading: false });
        }
        else {
            this.setState({ filterLoading: false });
        }
        this.search();
    }

    addNewStatement(serviceId, newStatement) {
        const items = this.state.items.map(x => x.id === serviceId ? { ...x, statements: [...x.statements, newStatement] } : x);
        this.setState({ items });
    }

    render() {
        return (
            <ListViewWrapper
                title="Servicios"
                buttons={[
                    (<CreateButton
                        key="1"
                        onCreate={() => this.props.history.push("/services/create")}
                        text="Nuevo servicio"
                    />)
                ]}>
                <TableActions>
                    {this.state.filterLoading && <Spinner show={true} />}
                    {!this.state.filterLoading &&
                        <FilterComponent
                            filter={this.state.filter}
                            onChange={x => this.setState({ filter: new ServiceFilter(x) })}
                            onFilter={this.search}
                            initialCustomer={this.state.initialCustomer}
                        />}
                </TableActions>

                <Table
                    onBottomScroll={this.handleBottomScroll}
                    services={this.state.items}
                    loading={this.state.loading}
                    addNewStatement={this.addNewStatement.bind(this)}
                />
            </ListViewWrapper>
        );
    }
}

export default withRouter(ServicesListPage);
